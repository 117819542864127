//React
import React from 'react'

//Packages
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <div class="container container-page container-page-demo-3">
      <div class="row d-flex flex-column justify-content-center align-items-center text-center">
        <div class="col-lg-8">
          <h3>Oops!</h3>
        </div>

        <div class="col-lg-8">
          <p>We couldn't find the page you were looking for.</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
